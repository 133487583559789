import React from 'react';
import Slider from 'react-slick';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Link } from '@mui/material';

const mock = [
  
    {
      feedback:
        "Registration for admission to Montessori, Kindergarten, and Class 11 for the academic year 2025–26 is now open",
        href : '/admission-criteria',
  
      },
      
  //   {
  //   feedback:
  //   // 'Registration for admission to Montessori 1, Pre-K and Kindergarten 1 for the academic year 2023-24 is now open. ',
  //   "Registeration for admission to Montessori, Kindergarten, Class 11 for Academic year 2024-25 is open",
  //   href : '/admission-criteria',

  // },
  // {
  //   feedback:
  //     'Registration for admission to classes 1,2,5,9 and 11 for the academic year 2023-24 is open.',
  //     href : '/admission-criteria',

  //   },

];

const Message = () => {
  
  const sliderOpts = {
    
    infinite: true,
    speed: 12000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
   
  };

  return (
    <Box  margin={'0 auto'}>
    {/* // style = {{backgroundImage: 'https://www.npsypr.com/images/banner/2k22/banner_i1_2k22.jpg'}}> */}
  
      
      <Slider {...sliderOpts}>
        {mock.map((item, i) => (
          <Box key={i} paddingX={4}>
            <Link href = {item.href}>
            <Typography
              align={'justify'}
              component = 'p'
           
              sx={{ color: 'blue' }}
            >
              {item.feedback}
            </Typography></Link>
          </Box>
        ))}
      </Slider>
      
    </Box>
  );
};

export default Message;
